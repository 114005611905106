import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";
import StudyAndWork from "../components/homepage/study";

import INFO from "../data/user";
import SEO from "../data/seo";
import { useTranslation } from "react-i18next";

import {CsharpOriginal, CplusplusOriginal, PythonOriginal, GoOriginal, JavaOriginal, ReactOriginal} from "devicons-react"


import "./styles/about.css";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { t } = useTranslation();
	const currentSEO = SEO.find((item) => item.page === "about");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`About | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					

					<div className="about-container">
						<div className="about-main">
							<div className="about-left-side">
								<div className="title about-title">
									{t("pages:About.Title")}
								</div>

								<div className="subtitle about-subtitle">
									{t("pages:About.Description")}
								</div>
								<div className="skills">
									<h3>{t("pages:About.Skills.MySkills")}:</h3>
									<div>
									<b>Unity Engine:</b>
										<ul>
											<li>C#</li>
											<li>Unity API</li>
											<li>Editor Scripting + Editor Tools</li>
											<li>UI Toolkit</li>
											<li>AR and VR applications</li>
										</ul>
									</div>
									<div>
										<b>Programming Languages:</b>
										<ul className="about-proglang-list">
											<li className="about-proglang-list-ele"><CsharpOriginal color="red" size="26" /> <b>C#</b></li>
											<li className="about-proglang-list-ele"><CplusplusOriginal color="red" size="26"/><b>C++</b></li>
											<li className="about-proglang-list-ele"><PythonOriginal color="red" size="26"/><b>Python</b></li>
											<li className="about-proglang-list-ele"><ReactOriginal color="red" size="26"/><b>React</b></li>
											<li className="about-proglang-list-ele"><GoOriginal color="red" size="26"/><b>Go</b></li>
											<li className="about-proglang-list-ele"><JavaOriginal color="red" size="26"/><b>Java</b></li>
										</ul>
									</div>
									<div>
										<b>Blender:</b>{" "}
										{t("pages:About.Skills.Blender")}
									</div>
								</div>
							</div>

							<div className="about-right-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="about.jpg"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="about-study">
							<StudyAndWork />
						</div>

						<div className="about-socials">
							<Socials />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
