import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enCommon from "./data/translations/common/en.json";
import enPages from "./data/translations/pages/en.json";
import deCommon from "./data/translations/common/de.json";
import dePages from "./data/translations/pages/de.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    common: enCommon,
    pages: enPages,
    },

  de: {   
    common: deCommon,
    pages: dePages,
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['common','pages'],
    defaultNS: 'common',
    resources,
    whitelist: ['en','de'],
    fallbackLng: 'en',
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;