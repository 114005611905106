const SEO = [
	{
		page: "home",
		description:
			"I am a Game Developer with expertise in Unity Engine and C#",
		keywords: ["Matthias", "Matthias Mitschele", "Matzgo"],
	},

	{
		page: "about",
		description:
			"I am a Game Developer with expertise in Unity Engine and C#",
		keywords: ["Matthias", "Matthias Mitschele", "Matzgo"],

	},

	{
		page: "assets",
		description:
		"I am a Game Developer with expertise in Unity Engine and C#",
		keywords: ["Matthias", "Matthias Mitschele", "Matzgo"],

	},

	{
		page: "projects",
		description:
		"I am a Game Developer with expertise in Unity Engine and C#",
		keywords: ["Matthias", "Matthias Mitschele", "Matzgo"],

	},

	{
		page: "contact",
		description:
		"I am a Game Developer with expertise in Unity Engine and C#",
		keywords: ["Matthias", "Matthias Mitschele", "Matzgo"],

	},
];

export default SEO;
