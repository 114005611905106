import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";



import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import { faPersonDigging } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import INFO from "../data/user";
import SEO from "../data/seo";
import { useTranslation } from 'react-i18next';


import "./styles/assets.css";

const Assets = () => {
	const  {t, i18n} = useTranslation();


	const currentSEO = SEO.find((item) => item.page === "assets");
	return (
		<React.Fragment>
			<Helmet >
				<title>{`Assets | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="assets" />
				<div className="content-wrapper">
					{/* <div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div> */}

					<div className="assets-container">
						<div className="projects-title">
							Under Construction
						</div>
						<FontAwesomeIcon icon={faPersonDigging} size="4x" ></FontAwesomeIcon>

					</div>
						<div className="page-footer">
							<Footer />
						</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Assets;
