import React from "react";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/study.css";

const StudyAndWork = () => {
	return (
		<div className="works">
			<Card
				icon={faGraduationCap}
				title="Study"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./tumLogo2.png"
								alt="tum"
								className="work-image"
							/>
							<div className="work-title">
								Technical University of Munich
							</div>
							<div className="work-subtitle">
								B.Sc. Informatics: Games Engineering
							</div>
							<div className="work-duration">2016 - 2021</div>
						</div>

						<div className="work">
							<img
								src="./tumLogo2.png"
								alt="tum"
								className="work-image"
							/>
							<div className="work-title">
								Technical University of Munich
							</div>
							<div className="work-subtitle">
								M.Sc. Informatics: Games Engineering
							</div>
							<div className="work-duration">2021 - Present</div>
						</div>
					</div>
				}
			/>

			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./logo.png"
								alt="tum"
								className="work-image"
							/>
							<div className="work-title">
								Freelance
							</div>
							<div className="work-subtitle">
								Unity Developer / 3D Modeling
							</div>
							<div className="work-duration">2020 - 2023</div>
						</div>

						<div className="work">
							<img
								src="./elfLogo.png"
								alt="tum"
								className="work-image"
							/>
							<div className="work-title">
								European League of Football
							</div>
							<div className="work-subtitle">
								Working Student: 3D Modeling
							</div>
							<div className="work-duration">2022</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default StudyAndWork;
